import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig } from '@app/app.config';
import { IResponse, SalesTaxConfig } from '@shared/meta-data';
import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { TaxConfigStore } from '@app/@store/sales-tax-config/tax-config-store';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SalesTaxConfigService {
  private readonly handleError: HandleError;
  private router: Router = inject(Router);  

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private taxConfigStore: TaxConfigStore,
  ) {
    this.handleError = httpErrorHandler.createHandleError('Sales Tax Config');
  }


  /**
   * Makes an api call to our server to fetch the available sales tax configuration.
   * If successful, It saves this data to our store upon fetching it and returns an observable of the available config
   *
   * @return {*}  {Observable<Array<StateConfig>>}
   * @memberof SalesTaxConfigService
   */
  fetchSalesTaxConfig(): Observable<SalesTaxConfig> {
    return this.httpClient
      .get<IResponse<any>>(`${AppConfig.API_URL}/sales-tax/config`)
      .pipe(
        // catchError(this.silence404Error({ content: { state_schedules: null } })),
        map(({ content: { state_schedules, state_metadata } }) => (this.taxConfigStore.saveSalesTaxConfig(state_schedules), this.taxConfigStore.saveStateMetadata(state_metadata))),
        catchError(this.handleNon401Errors())  
      );
  }

  getConfig(): any {
    const config = this.taxConfigStore.retrieveAllTaxConfig();
    return config ?? null;
  }

  private silence404Error(result = null) {
    return (err: any) => {
      return err.status === 404
        ? of(result)
        : throwError(() => err);
    };
  }

  /**
   * Handles non-401 HTTP errors by redirecting to a 500 error page and delegates the `handleError method for 401 errors`.
   * 
   * @returns A function that can be used in RxJS error handling pipelines.
   * @description
   * This method returns a function that handles HTTP errors in the following way:
   * 1. If the error status is not 401 (Unauthorized):
   *    - It navigates to the '/500' route (an server error page).
   *    - The navigation is done with `skipLocationChange: true` to prevent the 
   *      navigation from being added to the browser's history. This ensures that the
   *      route url is same as the initial route before the 500 page.
   *    - It then re-throws the original error using RxJS's `throwError` operator.
   
    * Usage:
    * This method is typically used in RxJS pipelines to handle errors from HTTP requests.
    * For example:
    * ```
    * this.http.get('/api/data').pipe(
    *   catchError(this.handleNon401Errors())
    * ).subscribe(...)
    * ```
    * 
    * @returns A function that takes an error object and returns an Observable.
   */
  private handleNon401Errors() {
    return (err: any) => {
      if (err.status !== 401) {
        this.router.navigate(['/500'], { skipLocationChange: true });
        return throwError(() => err);
      }
      return this.handleError('Sales Tax Report', null, { showToast: false })(err);
    };
  }
}
